<template>
        <div  class="main-content">
            <loading :active.sync="isLoading"
                     :is-full-page="fullPage"></loading>

            <div class="container-fluid" style="margin-top:0px;" >

                <div class="col-lg-12">
                    <div class="card " style="margin-top: 10px;">
                        <div class="card-header bg-info">
                            <h4 class="m-b-0 text-white" style="font-weight: 700;">Quotation</h4>
                        </div>
                        <div class="card-body">

                            <div class="row mb-4">
                                <div class="col-md-6">
                                    <img src="@/assets/images/kings_mabati.png" style="width: 200px;height: 100px">
                                </div>
                                <div class="col-md-6">
                                    <div class="float-right">
                                        <strong>KINGS MABATI COMPANY LIMITED,</strong><br/>
                                        <strong>NATIONAL PARK EAST GATE ROAD,</strong><br/>
                                        <strong>NAIROBI - KENYA</strong><br/>
                                        <strong>Tel: {{ quotation.salesRep.phoneNumber }}/0114030351</strong><br/>
                                        <strong>EMAIL :info@kingsmabati.co.ke</strong>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <hr/>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4">
                                    <table class="table ">
                                        <tr>
                                            <th>Customer Name:</th>
                                            <td>{{ quotation.customer.clientName }}</td>
                                        </tr>
                                        <tr>
                                            <th>Customer Mobile Number:</th>
                                            <td>{{ quotation.customer.phoneNumber }}</td>
                                        </tr>
                                        <tr>
                                            <th>ID Number:</th>
                                            <td>{{ quotation.customer.phoneNumber }}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-4">

                                </div>
                                <div class="col-md-4">
                                    <table class="table ">

                                        <tr>
                                            <th>Ship To:</th>
                                            <td>{{ quotation.destination }}</td>
                                        </tr>
                                        <tr>
                                            <th>Sales Rep:</th>
                                            <td>{{ quotation.salesRep.name }}</td>
                                        </tr>
                                        <tr>
                                            <th>Date:</th>
                                            <td>{{ quotation.quotationDate}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div class="row mb-4">
                                <div class="col-md-12">
                                    <table class="table color-table table-bordered">
                                        <tr>
                                            <th>S/No.</th>
                                            <th>Description</th>
                                            <th>Quantity</th>
                                            <th>Price Per Metre.</th>
                                            <th>Total</th>
                                        </tr>
                                        <tr v-for="item in quotation.items">
                                            <td >
                                                {{ item.serialNo }}
                                            </td>
                                            <td>{{ item.product.productName}} <span v-if="item.product.productType == 'IRON_SHEET'">G - {{ item.product.gauge }}</span></td>
                                            <td>
                                                {{ item.qty | number('0,0')}}
                                            </td>
                                            <td>
                                                {{ item.pricePerMetre | number('0,0')}}
                                            </td>
                                            <td>
                                                <div v-if="item.product && item.product.productType == 'IRON_SHEET'">
                                                             <span v-if="item.qty && item.sizeInMetres" >
                                                              {{(parseFloat(item.qty) * parseFloat(item.sizeInMetres) * parseFloat(item.pricePerMetre)) - item.discount  | number('0,0') }}
                                                            </span>
                                                </div>
                                                <div v-else-if="item.product && item.product.productType == 'GENERAL'">
                                                            <span v-if="item.qty" >
                                                              {{( parseFloat(item.qty)  * parseFloat(item.pricePerMetre)) - item.discount | number('0,0') }}
                                                            </span>
                                                </div>

                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="4">
                                                Total:
                                            </th>
                                            <th>
                                                KES. {{grossAmount | number('0,0')}}
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div class="row mb-4">
                                <div class="col-md-4">
                                    <table class="table ">
                                        <tr>
                                            <th>MODE OF PAYMENT:</th>
                                            <td>LIPA NA M-PESA</td>
                                        </tr>
                                        <tr>
                                            <th>PAYBILL NO:</th>
                                            <td>600100</td>
                                        </tr>
                                        <tr>
                                            <th>A/C NO:</th>
                                            <td>0100007243353</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-1">
                                </div>
                                <div class="col-md-2">
                                    <a  v-if="quotation.status == 'PENDING'" :href="'/app/pages/editQuotation/'+quotation.id" class="btn btn-success btn-block">Edit</a> <br/>
                                    <a :href="'/app/pages/downloadQuotation/'+quotation.id" class="btn btn-danger btn-block">Preview To Download</a>
                                </div>
                                <div class="col-md-5">
                                </div>

                            </div>



                        </div>
                    </div>
                </div>
            </div>

        </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { mapGetters, mapActions } from "vuex";
    import createCustomer from './CreateCustomerModal'
    import createLocation from './CreateLocationModal'

    export default {
        name: "CreateQuotation",

        data() {
            return {
                quotation:{
                    customer:{
                        clientName:"",
                        phoneNumber:""
                    },
                    shipmentLocation:{
                        locationName:""
                    },
                    salesRep:{
                        name:""
                    },
                    quotationDate:"",
                    items:[
                    ]
                },
                serialNo:0,
                delivery_fees:[],
                isLoading: false,
                products:[],
                commission:{},
                fullPage: true,
            }
        },

        methods:{
            ...mapActions(["getQuotationDetails"]),


            getQuotationDetailsData(){
                let self = this;
                this.getQuotationDetails({id: this.$route.params.id })
                    .then(function (quotation) {


                        self.quotation = quotation;

                        let serialNo = 1;

                        self.quotation.items = quotation.items.map(function (item) {
                            item.serialNo = serialNo;
                            serialNo++;
                            return item;
                        });

                    })
                    .catch(function (error) {



                    })
            },


        },
        mounted (){
            this.getQuotationDetailsData();

        },
        computed: {
            incrementSerialNo(){

                let serialNo =  this.serialNo = this.serialNo + 1;
                console.log(serialNo);
                return serialNo;
            },

            grossAmount(){

                let fixed_amount = 0;

                this.quotation.items.forEach(function(quotation_item){
                    if(quotation_item.product && quotation_item.product.productType == 'IRON_SHEET'){
                        fixed_amount +=  (parseFloat(quotation_item.qty) * parseFloat(quotation_item.sizeInMetres) * parseFloat(quotation_item.pricePerMetre)) - quotation_item.discount
                    }
                    else{
                        fixed_amount += (parseFloat(quotation_item.qty)  * parseFloat(quotation_item.pricePerMetre)) - quotation_item.discount;
                    }

                });

                return fixed_amount;
            },
        },
        components: {
            Loading,
        }
    }
</script>

