<template>
        <div>
            <loading :active.sync="isLoading"
                     :is-full-page="fullPage"></loading>

            <b-modal  id="addlocation" title="Add Location" @cancel="true" @ok="validateBeforeSubmitNewLocation"   centered>
                <form action="#" class="form-horizontal"  method="post">
                    <input type="hidden" name="_token" />
                    <div class="form-body">

                        <div class="row" >
                            <div class="col-md-12">
                                <div class="form-group ">
                                    <label class="control-label ">Name:</label> <br/>
                                    <div >
                                        <input class="form-control" type="text"  v-model="location.locationName" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" >
                            <div class="col-md-12">
                                <div class="form-group ">
                                    <label class="control-label ">County:</label> <br/>
                                    <div >
                                        <select class="form-control" v-model="location.county">
                                            <option :value="county.name" v-for="county in counties">{{ county.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </form>
            </b-modal>
        </div>
</template>

<script>

    import { mapGetters, mapActions } from "vuex";

    import {counties} from "@/data/counties";


    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: "addlocation",
        data(){
            return{
                isLoading: false,
                fullPage: true,
                counties,
                location:{
                    id:0,
                    locationName:"",
                    county:""
                }
            }

        },
        components: {
            Loading
        },
        mounted (){

        },
        methods:{
            ...mapActions(["createLocation","getLocations"]),

            openModal(){
                this.$bvModal.show('addlocation');
            },

            getLocationsData(){
                let self = this;
                this.getLocations()
                    .then(function (locations) {
                        //self.customers = customers;

                    })
                    .catch(function (error) {



                    })
            },

            validateBeforeSubmitNewLocation(){
                this.isLoading = true;

                let self = this;

                this.createLocation(this.location).then(() => {
                    self.isLoading = false;

                    self.getLocationsData();
                    self.$bvToast.toast("Location saved.", {
                        title: 'Success',
                        variant: 'success',
                        autoHideDelay: 5000,
                        appendToast: false
                    });


                })
                    .catch(error => {

                        self.isLoading = false;

                        self.$bvToast.toast(error.message, {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000,
                            appendToast: false
                        });


                    })
            }
        },
        computed:{

        }
    }
</script>

<style scoped>

</style>
