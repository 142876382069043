<template>
        <div>
            <loading :active.sync="isLoading"
                     :is-full-page="fullPage"></loading>

            <b-modal  id="add-customer" title="Add Customer" @cancel="true" @ok="validateBeforeSubmitNewCustomer"   centered>
                <form action="#" class="form-horizontal"  method="post">
                    <input type="hidden" name="_token" />
                    <div class="form-body">

                        <div class="row" >
                            <div class="col-md-12">
                                <div class="form-group ">
                                    <label class="control-label ">Name:</label> <br/>
                                    <div >
                                        <input class="form-control" type="text"  v-model="customer.clientName" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" >
                            <div class="col-md-12">
                                <div class="form-group ">
                                    <label class="control-label ">Phone number:</label> <br/>
                                    <div >
                                        <input class="form-control" type="text"  v-model="customer.phoneNumber" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" >
                            <div class="col-md-12">
                                <div class="form-group ">
                                    <label class="control-label ">Email Address:</label> <br/>
                                    <div >
                                        <input class="form-control" type="text"  v-model="customer.emailAddress" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </form>
            </b-modal>
        </div>
</template>

<script>

    import { mapGetters, mapActions } from "vuex";


    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: "addcustomer",
        data(){
            return{
                isLoading: false,
                fullPage: true,
                customer:{
                    id:0,
                    clientName:"",
                    phoneNumber:"",
                    emailAddress:""
                }
            }

        },
        components: {
            Loading
        },
        mounted (){

        },
        methods:{
            ...mapActions(["createCustomer","getCustomers"]),

            openModal(){
                console.log("Adding new modal");
                this.$bvModal.show('add-customer');
            },


            getCustomersData(){
                let self = this;
                this.getCustomers()
                    .then(function (customers) {

                    })
                    .catch(function (error) {



                    })
            },

            validateBeforeSubmitNewCustomer(){
               // this.isLoading = true;

                let self = this;

                this.createCustomer(this.customer).then(() => {
                    //self.isLoading = false;

                    self.getCustomersData();

                    self.$bvToast.toast("Customer saved.", {
                        title: 'Success',
                        variant: 'success',
                        autoHideDelay: 5000,
                        appendToast: false
                    });


                })
                    .catch(error => {

                        self.isLoading = false;

                        self.$bvToast.toast(error.message, {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000,
                            appendToast: false
                        });


                    })
            }
        },
        computed:{

        }
    }
</script>


